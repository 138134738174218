<template>
  <v-dialog :value="isShow" :max-width="800" @click:outside="ok">
    <v-card>
      <v-card-title class="blue-grey lighten-3" primary-title>{{ title }}</v-card-title>
      <v-card-text class="pa-4">
        <p>{{ message }}</p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="ok"> OK </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped></style>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: "確認",
      required: false,
    },
    message: {
      type: String,
      required: true,
    },
  },
  methods: {
    ok() {
      this.$emit("update:isShow", false);
    },
  },
};
</script>
