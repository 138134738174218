<template>
  <v-dialog
    :value="isShow"
    @click:outside="cancel"
    @input="$emit('update:isShow', $event)"
    :max-width="800"
  >
    <v-card>
      <v-card-title class="blue-grey lighten-3" primary-title>{{ title }}</v-card-title>
      <v-card-text class="pa-4">
        <p>{{ message }}</p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="ok"> OK </v-btn>
        <v-btn @click="cancel"> キャンセル </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped></style>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: "確認",
      required: false,
    },
    message: {
      type: String,
      required: true,
    },
    okAction: {
      type: Function,
      required: true,
    },
    cancelAction: {
      type: Function,
      default: () => {},
      required: false,
    },
  },
  methods: {
    ok() {
      this.$emit("update:isShow", false);
      this.okAction();
    },
    cancel() {
      this.cancelAction();
      this.$emit("update:isShow", false);
    },
  },
};
</script>
